import * as React from 'react'
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const styles = {

  orderedList: {
    display: "flex",
    justifyContent: "space-between",
    margin: "100px",
  },

  lines: {
    display: "flex",
    position: "relative",
    width: "50%",
    justifyContent: "center"
  },
  greenCircles: {
    alignItems: "center",
    backgroundColor: "#529736",
    borderRadius: "100%",
    color: "white",
    display: "flex",
    justifyContent: "center",
    opacity: "0",

    height: "200px",
    top: "-75px",
    width: "200px"
  },

  circleOne: {
    animation: "slideDown .75s ease-in-out forwards",
    backgroundColor: "#b2d235",
  },

  circleTwo: {
    animation: "slideDown .75s ease-in-out 1s forwards",
    backgroundColor: "#529836",
  },

  circleThree: {
    animation: "slideDown .75s ease-in-out 1.75s forwards",
    backgroundColor: "#02746d",
  },

  circleContent: {
    position: "absolute",
    textAlign: "center",
    top: "125%",
    width: "150%"

  }
}


const mostImportant = (props)=> {
	let param = props.location.search;
	let cat = param.slice(5)

		    // <div className="phone" style={{position: "absolute", bottom:"0", left:"0", width:"200px"}}>
		    // 	<StaticImage
		    //         alt="Phone with multiple bubbles around it for home, lighting, cooling, and heating"
		    //         src="../../images/importantPhone.svg"
		    //     />
		    // </div>
	return(
		<Layout pageTitle='What is Most Important to Your Facility?' id="works">
		<h2 style={{marginBottom:0}}>Tap on an option below:</h2>
	        <div style={{ display: "flex" }}>
	          <div style={{ width: "80%", margin:"0 auto" }}>
	            <div>
		          <ol style={styles.orderedList}>
		            <li style={ {...styles.lines}}>
		              <a href={`/Graphic${cat}/?filter=cost`} style={{textAlign: "center",textDecoration: "none"}}>
		              	<div style={{...styles.greenCircles, ...styles.circleOne}}>
			                <span style={{ fontWeight: "600", }} >Cost Reduction</span>
			              </div>
		              </a>
		            </li>
		            <li style={ {...styles.lines} }>
		            	<a href={`/Graphic${cat}/?filter=carbon`} style={{textAlign: "center",textDecoration: "none"}}>
			              <div style={{...styles.greenCircles, ...styles.circleTwo}}>
			                <span style={{ fontWeight: "600", textAlign: "center" }}>Carbon Reduction</span>
			              </div>
			            </a>
		            </li>
		            <li style={ {...styles.lines} }>
		            	<a href={`/Graphic${cat}/?filter=energy`} style={{textAlign: "center",textDecoration: "none"}}>
			              <div style={{...styles.greenCircles, ...styles.circleThree}}>
			                <span style={{ fontWeight: "600", textAlign: "center" }}>Energy Usage Reduction</span>
			              </div>
		              	</a>
		            </li>
		          </ol>
		        </div>
	          </div>
	        </div>
      </Layout>
	)
}

export default mostImportant